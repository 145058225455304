import Fab from '@material-ui/core/Fab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ImageIcon from '@material-ui/icons/Image';
import { format } from 'd3-format';
import domtoimage from 'dom-to-image';
import moment from 'moment';
import 'moment/locale/el';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import Fade from 'react-reveal/Fade';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  ChartLabel,
  Hint,
  HorizontalGridLines,
  LineSeries,
  VerticalGridLines,
  VerticalRectSeries,
  XAxis,
  XYPlot,
  YAxis,
} from 'react-vis';
import 'react-vis/dist/style.css';
import { DataHandling } from '../../src/context/DataContext';
import { DateIndexHandling } from '../../src/context/DateIndexContext';
import { LoadingHandling } from '../../src/context/LoadingContext';
import { TypeHandling } from '../../src/context/TypeContext';
import f from '../../src/format/format';
import PlotLoader from '../assets/PlotLoader';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  buttonGroup: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.secondary.main,
  },
  selectedDateRange: {
    color: theme.palette.secondary.main,
    background: theme.palette.primary.main,
    '&.Mui-selected': {
      color: theme.palette.primary.main,
      background: theme.palette.secondary.main,
    },
  },
  imagePlot: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  plot: {
    padding: theme.spacing(1),
    height: 350,
    width: '100%',
  },
  dateDisplayButton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 0,
    cursor: 'auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  fabButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    boxShadow: 'none',
  },
  centerDate: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textTransform: 'none',
  },
  fab: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  hint: {
    border: '1px solid',
    borderRadius: 5,
    borderColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  },
}));

const BarChart = (props) => {
  const classes = useStyles();
  const { currentDateIndex } = useContext(DateIndexHandling);
  const [dataPlot, setDataPlot] = useState([]);
  const [dataLinePlot, setDataLinePlot] = useState([]);
  const { data } = useContext(DataHandling);
  const { regDelCompareType } = useContext(TypeHandling);
  const [legends, setLegends] = useState([]);
  const [min, setMin] = useState();
  const [max, setMax] = useState();
  const [value, setValue] = useState(false);
  const theme = useTheme();
  const router = useRouter();
  const { loadingState } = useContext(LoadingHandling);

  useEffect(() => {
    if (data && data !== undefined) {
      setDataPlot(getData(data));
    }
  }, [data]);

  const MouseOver = (v) => {
    setValue(v);
  };

  const getData = (data) => {
    try {
      var array = JSON.parse(JSON.stringify(data));
      var arrayLine = JSON.parse(JSON.stringify(data));
      let legendsArray = [];
      let changeArray = [];
      let datesArrayMin = [];
      let datesArrayMax = [];
      array.map((option) => option.map((x) => (x.x = Date.parse(x.x))));
      arrayLine.map((option) => option.map((x) => (x.x = Date.parse(x.x))));
      for (const i in array) {
        if (i > 0) {
          if (array[0].length !== array[1].length) {
            if (array[1].length > array[0].length) {
              let diff = array[1].length - array[0].length;
              let diffVar;
              for (diffVar = 0; diffVar < diff; diffVar++) {
                array[1].pop();
                arrayLine[1].pop();
              }
            }
            if (array[0].length > array[1].length) {
              let diff = array[0].length - array[1].length;
              let diffVar;
              for (diffVar = 0; diffVar < diff; diffVar++) {
                array[0].pop();
                arrayLine[0].pop();
              }
            }
          }
          for (const j in array[i]) {
            array[i][j].change = array[0][j].y - array[1][j].y;
            arrayLine[i][j].y = arrayLine[0][j].y - arrayLine[1][j].y;
            array[i][j].xHint = array[0][j].x;
            array[i][j].xChangeHint = array[1][j].x;

            array[i][j].x = array[0][j].x;
            arrayLine[i][j].x = arrayLine[0][j].x;
            if (currentDateIndex == 0) {
              array[i][j].x0 = moment(array[i][j].x).subtract(40000, 'seconds');
              array[i][j].x = moment(array[i][j].x).add(40000, 'seconds');
              datesArrayMin.push(array[i][j].x0);
              datesArrayMax.push(array[i][j].x);
            } else if (currentDateIndex == 1) {
              array[i][j].x0 = moment(array[i][j].x).subtract(0.45, 'week');
              array[i][j].x = moment(array[i][j].x).add(0.45, 'week');
              datesArrayMin.push(array[i][j].x0);
              datesArrayMax.push(array[i][j].x);
            } else if (currentDateIndex == 2) {
              array[i][j].x0 = moment(array[i][j].x).subtract(1, 'weeks');
              array[i][j].x = moment(array[i][j].x).add(1, 'weeks');
              datesArrayMin.push(array[i][j].x0);
              datesArrayMax.push(array[i][j].x);
            } else if (currentDateIndex == 3) {
              array[i][j].x0 = moment(array[i][j].x).subtract(5, 'months');
              array[i][j].x = moment(array[i][j].x).add(5, 'months');
              datesArrayMin.push(array[i][j].x0);
              datesArrayMax.push(array[i][j].x);
            }
            if (array[0][j].y - array[1][j].y > 0) {
              array[i][j].y = array[0][j].y - array[1][j].y;
              array[i][j].y0 = 0;
              array[i][j].color = myPalette[0];
              array[i][j].colorId = '0';
            } else {
              array[i][j].y0 = array[0][j].y - array[1][j].y;
              array[i][j].y = 0;
              array[i][j].color = myPalette[1];
              array[i][j].colorId = '1';
            }
          }
          changeArray.push(
            array.map((option) => option.map((change) => change.change))
          );
          changeArray = changeArray[0][1];
          let min = Math.min(...datesArrayMin);
          let max;
          if (currentDateIndex == 0) {
            max = moment(min).add(array[0].length, 'days');
            min = moment(min).subtract(40000, 'seconds');
            max = moment(max).add(40000, 'seconds');
          } else if (currentDateIndex == 1) {
            max = moment(min).add(array[0].length, 'week');
            min = moment(min).subtract(0.45, 'week');
            max = moment(max).add(0.45, 'week');
          } else if (currentDateIndex == 2) {
            max = moment(min).add(array[0].length, 'months');
            min = moment(min).subtract(1, 'weeks');
            max = moment(max).add(1, 'weeks');
          } else if (currentDateIndex == 3) {
            max = moment(min).add(array[0].length, 'years');
            min = moment(min).subtract(5, 'months');
            max = moment(max).add(5, 'months');
          }
          setMin(min);
          setMax(max);
        }
      }
      setLegends(legendsArray);
      setDataLinePlot(arrayLine[1]);
      return array;
    } catch (err) {
      console.error(err);
    }
  };

  const saveImage = () => {
    domtoimage
      .toPng(document.getElementById('barchart'), { quality: 1 })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'bar-diagram.jpeg';
        link.href = dataUrl;
        link.click();
      });
  };

  const myPalette = [
    `${theme.palette.difRise.main}`,
    `${theme.palette.difFall.main}`,
  ];

  const dateLabel = () => {
    switch (currentDateIndex) {
      case 0:
        return 'Ημ/νία';
      case 1:
        return 'Εβδομάδα';
      case 2:
        return 'Μήνας';
      case 3:
        return 'Έτος';
    }
  };
  const dateFormat = (value) => {
    switch (currentDateIndex) {
      case 0:
        return moment(value).format('DD/MM/YYYY');
      case 1:
        return moment(value).format('DD/MM/YYYY');
      case 2:
        return moment(value).format('MM/YYYY');
      case 3:
        return moment(value).format('YYYY');
      case 4:
        return moment(value).format('DD/MM/YYYY');
      case 5:
        return moment(value).format('DD/MM/YYYY');
    }
  };

  return (
    <>
      {dataPlot.length > 0 && !loadingState ? (
        <div className={classes.imagePlot}>
          {/* <Fade> */}
          <Fab
            aria-label='edit'
            type='submit'
            size='small'
            className={classes.fab}
            style={{ verticalAlign: 'center' }}
            onClick={saveImage}
          >
            <ImageIcon color='secondary' />
          </Fab>
          <div id='barchart' className={classes.plot}>
            <AutoSizer>
              {({ height, width }) => (
                <>
                  <XYPlot
                    //   yDomain={[min, max]}
                    xDomain={[min, max]}
                    yType='linear'
                    xType='time'
                    width={width}
                    margin={{ left: 45, top: 20, right: 45 }}
                    height={height}
                  >
                    <VerticalGridLines />
                    <HorizontalGridLines />
                    <VerticalRectSeries
                      key={JSON.stringify(dataPlot[1])}
                      data={dataPlot[1]}
                      colorType='literal'
                      onNearestXY={MouseOver}
                      onSeriesMouseOut={() => setValue(false)}
                    />
                    {value !== false && (
                      <Hint value={value} id='hint'>
                        <div className={classes.hint}>
                          <div>
                            {dateLabel()} : {dateFormat(value.xHint)}
                          </div>
                          {regDelCompareType ? (
                            <></>
                          ) : (
                            <div>
                              {dateLabel()} Σύγκρισης :{' '}
                              {dateFormat(value.xChangeHint)}
                            </div>
                          )}
                          <div style={{ display: 'inline-flex' }}>
                            Μεταβολή: &nbsp;{' '}
                            <div
                              style={{ color: myPalette[`${value.colorId}`] }}
                            >
                              {router.pathname.includes('allincome') ||
                              router.pathname.includes('gemiincome') ||
                              router.pathname.includes('allexpenses')
                                ? f(value.change).concat('€')
                                : value.change}
                            </div>
                          </div>
                        </div>
                      </Hint>
                    )}
                    <XAxis
                      tickTotal={isMobileOnly ? 2 : 6}
                      tickFormat={function tickFormat(d) {
                        switch (currentDateIndex) {
                          case 0:
                            return moment(d).format('DD/MM/YYYY');
                          case 1:
                            return moment(d).format('DD/MM/YYYY');
                          case 2:
                            return moment(d).format('MMM YYYY');
                          case 3:
                            return moment(d).format('YYYY');
                          case 4:
                            return moment(d).format('MMM');
                          case 5:
                            return moment(d).format('MMM');
                        }
                      }}
                    />
                    <YAxis
                      tickFormat={(tick) => {
                        let y = Math.round(tick) === tick ? tick : '';
                        if (String(y).length > 3) {
                          return format('.2s')(y);
                        } else {
                          return y;
                        }
                      }}
                    />
                    <LineSeries
                      key={JSON.stringify(dataLinePlot)}
                      data={dataLinePlot}
                      color={`${theme.palette.warning.light}`}
                      curve={'curveMonotoneX'}
                      style={{ strokeWidth: '3px' }}
                    />
                    <ChartLabel
                      text={
                        router.pathname.includes('allincome') ||
                        router.pathname.includes('gemiincome') ||
                        router.pathname.includes('allexpenses')
                          ? 'Ποσό (€)'
                          : ''
                      }
                      includeMargin={false}
                      xPercent={0}
                      yPercent={0.04}
                      style={{
                        transform: 'rotate(0)',
                        textAnchor: 'end',
                      }}
                    />
                  </XYPlot>
                </>
              )}
            </AutoSizer>
          </div>
          {/* </Fade> */}
        </div>
      ) : (
        <PlotLoader />
      )}
    </>
  );
};

export default BarChart;
