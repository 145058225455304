import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ImageIcon from '@material-ui/icons/Image';
import { format } from 'd3-format';
import f from '../../src/format/format';
import domtoimage from 'dom-to-image';
import moment from 'moment';
import 'moment/locale/el';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { isMobileOnly } from 'react-device-detect';
import Fade from 'react-reveal/Fade';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  AreaSeries,
  ChartLabel,
  DiscreteColorLegend,
  Hint,
  HorizontalGridLines,
  LineMarkSeries,
  // LineMarkSeriesCanvas,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
} from 'react-vis';
import 'react-vis/dist/style.css';
import { BodyHandling } from '../../src/context/BodyContext';
import { DataHandling } from '../../src/context/DataContext';
import { DateIndexHandling } from '../../src/context/DateIndexContext';
import { FreeRangeCompareHandling } from '../../src/context/FreeRangeCompareContext';
import { LoadingHandling } from '../../src/context/LoadingContext';
import { TypeHandling } from '../../src/context/TypeContext';
import PlotLoader from '../assets/PlotLoader';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  buttonGroup: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.secondary.main,
  },
  selectedDateRange: {
    color: theme.palette.secondary.main,
    background: theme.palette.primary.main,
    '&.Mui-selected': {
      color: theme.palette.primary.main,
      background: theme.palette.secondary.main,
    },
  },
  amountDescription: {
    display: 'inline-flex',
    justifyContent: 'space-evenly',
    width: '100%',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
    },
  },
  imagePlot: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  plot: {
    padding: theme.spacing(1),
    height: 350,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  fabButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    boxShadow: 'none',
  },
  centerDate: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textTransform: 'none',
  },
  fab: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  hint: {
    border: '1px solid',
    borderRadius: 5,
    borderColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  },
  noResultButton: {
    borderRadius: 0,
    cursor: 'auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    height: '50%',
  },
  headerText: {
    display: 'inline-flex',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
}));

const LinePlot = (props) => {
  const router = useRouter();
  const classes = useStyles();
  const theme = useTheme();
  const [dataPlot, setDataPlot] = useState([]);
  const [noData, setNoData] = useState(false);
  const [value, setValue] = useState(false);
  const { data } = useContext(DataHandling);
  const { currentDateIndex } = useContext(DateIndexHandling);
  const { freeRangeCompare } = useContext(FreeRangeCompareHandling);
  const { regDelCompareType } = useContext(TypeHandling);
  const { bodyInsight } = useContext(BodyHandling);
  const { loadingState } = useContext(LoadingHandling);

  const [legends, setLegends] = useState([]);
  const [maxValue, setMaxValue] = useState();
  const [minValue, setMinValue] = useState();

  useEffect(() => {
    if (data && data !== undefined) {
      let response = getData(data);
      let dataFound = true;
      for (const i in response) {
        if (response[i].length <= 0) {
          dataFound = false;
        }
      }
      if (dataFound) {
        setDataPlot(getData(data));
        setNoData(false);
      } else {
        setNoData(true);
      }
    }
  }, [data]);

  const getData = (data) => {
    try {
      let legendsArray = [];
      let arrayOfYValues = [];
      var array = JSON.parse(JSON.stringify(data));
      array.map((option) => option.map((x) => (x.x = Date.parse(x.x))));
      for (const i in array) {
        if (array[i].length > 0) {
          if (regDelCompareType) {
            if (i === '0') {
              legendsArray.push({
                title: 'Εγγραφές',
                color: myPaletteRegDel[i],
                strokeWidth: 6,
              });
            } else if (i === '1') {
              legendsArray.push({
                title: 'Διαγραφές',
                color: myPaletteRegDel[i],
                strokeWidth: 6,
              });
            }
          } else {
            legendsArray.push({
              title:
                String(dateFormat(array[i][0].x)) +
                '-' +
                String(dateFormat(array[i][array[i].length - 1].x)),
              color: myPalette[i],
              strokeWidth: 6,
            });
          }

          if (!freeRangeCompare) {
            if (array.length > 1) {
              if (array[0].length !== array[1].length) {
                if (array[1].length > array[0].length) {
                  let diff = array[1].length - array[0].length;
                  let diffVar;
                  for (diffVar = 0; diffVar < diff; diffVar++) {
                    array[1].pop();
                  }
                }
                if (array[0].length > array[1].length) {
                  let diff = array[0].length - array[1].length;
                  let diffVar;
                  for (diffVar = 0; diffVar < diff; diffVar++) {
                    array[0].pop();
                  }
                }
              }
              for (const j in array[i]) {
                array[i][j].xHint = array[i][j].x;
                array[i][j].x = array[0][j].x;
                array[i][j].y = array[i][j].y;
                array[i][j].color = regDelCompareType ? myPaletteRegDel[i] : myPalette[i];
              }
            } else {
              for (const k in array[i]) {
                array[i][k].xHint = array[i][k].x;
                array[i][k].x = array[0][k].x;
                array[i][k].y = array[i][k].y;
                array[i][k].color = myPalette[i];
              }
            }
          } else {
            for (const l in array[i]) {
              array[i][l].color = myPalette[i];
              array[i][l].xHint = array[i][l].x;
            }
          }
        }
      }
      for (const i in array) {
        let sum = 0;
        for (const j in array[i]) {
          arrayOfYValues.push(array[i][j].y);
          sum += array[i][j].y;
        }
        array[i].sum = sum;
      }
      setMaxValue(Math.max(...arrayOfYValues));
      setMinValue(Math.min(...arrayOfYValues));
      setLegends(legendsArray);
      return array;
    } catch (err) {
      console.error(err);
    }
  };

  const saveImage = () => {
    domtoimage
      .toPng(document.getElementById('lineplot'), { quality: 1 })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'line-diagram.jpeg';
        link.href = dataUrl;
        link.click();
      });
  };

  const MouseOver = (v) => {
    setValue(v);
  };

  const myPalette = [
    `${theme.palette.color6.main}`,
    `${theme.palette.color7.main}`,
  ];

  const myPaletteRegDel = [`${theme.palette.reg.main}`, `${theme.palette.del.main}`];

  const dateFormat = (value) => {
    switch (currentDateIndex) {
      case 0:
        return moment(value).format('DD/MM/YYYY');
      case 1:
        return moment(value).format('DD/MM/YYYY');
      case 2:
        return moment(value).format('MM/YYYY');
      case 3:
        return moment(value).format('YYYY');
      case 4:
        return moment(value).format('DD/MM/YYYY');
      case 5:
        return moment(value).format('DD/MM/YYYY');
    }
  };

  return (
    <>
      {noData ? (
        <div id='lineplot' className={classes.plot}>
          <AutoSizer>
            {({ height, width }) => (
              <>
                <div
                  style={{
                    width: width,
                    height: height / 4,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    id='filter-button'
                    type='submit'
                    variant='outlined'
                    className={classes.noResultButton}
                  >
                    Δε βρεθηκαν αποτελεσματα
                  </Button>
                </div>
                <ContentLoader
                  animate={false}
                  height={(3 * height) / 4}
                  width={width}
                  {...props}
                >
                  <rect
                    x='0'
                    y={height / (Math.random() * 10 + 1)}
                    rx='0'
                    ry='0'
                    width={width / 10}
                    height={height}
                  />
                  <rect
                    x={width / 5}
                    y={height / (Math.random() * 10 + 1)}
                    rx='0'
                    ry='0'
                    width={width / 10}
                    height={height}
                  />
                  <rect
                    x={(2 * width) / 5}
                    y={height / (Math.random() * 10 + 1)}
                    rx='0'
                    ry='0'
                    width={width / 10}
                    height={height}
                  />
                  <rect
                    x={(3 * width) / 5}
                    y={height / (Math.random() * 10 + 1)}
                    rx='0'
                    ry='0'
                    width={width / 10}
                    height={height}
                  />
                  <rect
                    x={(4 * width) / 5}
                    y={height / (Math.random() * 10 + 1)}
                    rx='0'
                    ry='0'
                    width={width / 10}
                    height={height}
                  />
                </ContentLoader>
              </>
            )}
          </AutoSizer>
        </div>
      ) : dataPlot && dataPlot.length > 0 && !loadingState ? (
        <>
          <div className={classes.amountDescription}>
            <Typography component='h6' variant='h6' colSpan={6}>
              {router.pathname.includes('gemiincome') ? (
                bodyInsight.type === 1 ? (
                  <div className={classes.headerText}>
                    Συνολικό Ποσό Επιμελητηρίου: &nbsp;{' '}
                    <div style={{ color: myPalette[0] }}>
                      {f(dataPlot[0].sum).concat('€')}
                    </div>
                  </div>
                ) : (
                  <div className={classes.headerText}>
                    Συνολικό Ποσό: &nbsp;{' '}
                    <div style={{ color: myPalette[0] }}>
                      {f(dataPlot[0].sum).concat('€')}
                    </div>
                  </div>
                )
              ) : router.pathname.includes('allincome') ||
                router.pathname.includes('allexpenses') ? (
                <div className={classes.headerText}>
                  Συνολικό Ποσό: &nbsp;{' '}
                  <div style={{ color: myPalette[0] }}>
                    {f(dataPlot[0].sum).concat('€')}
                  </div>
                </div>
              ) : regDelCompareType ? (
                <div className={classes.headerText}>
                  Πλήθος Εγγραφών: &nbsp;{' '}
                  <div style={{ color: myPaletteRegDel[0] }}> {dataPlot[0].sum}</div>
                </div>
              ) : (
                <div className={classes.headerText}>
                  Συνολικό Πλήθος: &nbsp;{' '}
                  <div style={{ color: myPalette[0] }}>{dataPlot[0].sum}</div>
                </div>
              )}
            </Typography>
            {dataPlot[1] ? (
              router.pathname.includes('allincome') ||
              router.pathname.includes('gemiincome') ||
              router.pathname.includes('allexpenses') ? (
                <Typography component='h6' variant='h6' colSpan={6}>
                  <div className={classes.headerText}>
                    Συνολικό Ποσό Σύγκρισης: &nbsp;{' '}
                    <div style={{ color: myPalette[1] }}>
                      {f(dataPlot[1].sum).concat('€')}
                    </div>
                  </div>
                </Typography>
              ) : regDelCompareType ? (
                <Typography component='h6' variant='h6' colSpan={6}>
                  <div className={classes.headerText}>
                    Πλήθος Διαγραφών : &nbsp;{' '}
                    <div style={{ color: myPaletteRegDel[1] }}>{dataPlot[1].sum}</div>
                  </div>
                </Typography>
              ) : (
                <Typography component='h6' variant='h6' colSpan={6}>
                  <div className={classes.headerText}>
                    Συνολικό Πλήθος Σύγκρισης: &nbsp;{' '}
                    <div style={{ color: myPalette[1] }}>{dataPlot[1].sum}</div>
                  </div>
                </Typography>
              )
            ) : (
              <></>
            )}
          </div>
          <div className={classes.imagePlot}>
            {/* <Fade> */}
            <Fab
              aria-label='edit'
              type='submit'
              size='small'
              className={classes.fab}
              style={{ verticalAlign: 'center' }}
              onClick={saveImage}
            >
              <ImageIcon color='secondary' />
            </Fab>
            <div id='lineplot' className={classes.plot}>
              <AutoSizer>
                {({ height, width }) => (
                  <>
                    <XYPlot
                      yDomain={
                        !router.pathname.includes('allexpenses')
                          ? maxValue === 0
                            ? [0, 1]
                            : [0, maxValue]
                          : minValue < 0
                          ? maxValue === 0
                            ? [minValue, 1]
                            : [minValue, maxValue]
                          : maxValue === 0
                          ? [0, 1]
                          : [0, maxValue]
                      }
                      xType='time'
                      yType='linear'
                      height={(90 * height) / 100}
                      width={width}
                      margin={{ left: 45, top: 20, right: 45 }}
                      style={{ position: 'relative' }}
                    >
                      <HorizontalGridLines />
                      <VerticalGridLines />
                      {dataPlot.map((option, i) => {
                        return (
                          <AreaSeries
                            key={JSON.stringify(option)}
                            data={option}
                            color={regDelCompareType ? fade(myPaletteRegDel[i], 0.1) : fade(myPalette[i], 0.1)}
                          />
                        );
                      })}
                      {dataPlot.map((option, i) => {
                        return (
                          <LineMarkSeries
                            key={JSON.stringify(option)}
                            data={option}
                            lineStyle={{ pointerEvents: 'none' }}
                            onValueMouseOver={MouseOver}
                            onSeriesMouseOut={() => setValue(false)}
                            color={regDelCompareType ? myPaletteRegDel[i] : myPalette[i]}
                          />
                        );
                      })}
                      {value !== false && (
                        <Hint value={value} id='hint'>
                          <div className={classes.hint}>
                            <div>Ημερομηνία: {dateFormat(value.xHint)}</div>
                            {router.pathname.includes('allincome') ||
                            router.pathname.includes('allexpenses') ? (
                              <div style={{ display: 'inline-flex' }}>
                                Ποσό: &nbsp;{' '}
                                <div style={{ color: `${value.color}` }}>
                                  {f(value.yString).concat('€')}
                                </div>
                              </div>
                            ) : router.pathname.includes('gemiincome') ? (
                              bodyInsight.type === 1 ? (
                                <>
                                  <div>
                                    <div style={{ display: 'inline-flex' }}>
                                      Ποσό Επιμελητηρίου: &nbsp;{' '}
                                      <div style={{ color: `${value.color}` }}>
                                        {f(value.yString).concat('€')}
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div style={{ display: 'inline-flex' }}>
                                      Συνολικό Ποσό: &nbsp;{' '}
                                      <div style={{ color: `${value.color}` }}>
                                        {f(value.yStringOther).concat('€')}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div>
                                    <div style={{ display: 'inline-flex' }}>
                                      Συνολικό Ποσό: &nbsp;{' '}
                                      <div style={{ color: `${value.color}` }}>
                                        {f(value.yString).concat('€')}
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div style={{ display: 'inline-flex' }}>
                                      Ποσό Επιμελητηρίου: &nbsp;{' '}
                                      <div style={{ color: `${value.color}` }}>
                                        {f(value.yStringOther).concat('€')}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                            ) : (
                              <div style={{ display: 'inline-flex' }}>
                                Πλήθος: &nbsp;{' '}
                                <div style={{ color: `${value.color}` }}>
                                  {format(',d')(value.y)}
                                </div>
                              </div>
                            )}
                          </div>
                        </Hint>
                      )}
                      <XAxis
                        tickTotal={isMobileOnly ? 2 : 6}
                        tickFormat={function tickFormat(d) {
                          switch (currentDateIndex) {
                            case 0:
                              return moment(d).format('DD/MM/YYYY');
                            case 1:
                              return moment(d).format('DD/MM/YYYY');
                            case 2:
                              return moment(d).format('MMM YYYY');
                            case 3:
                              return moment(d).format('YYYY');
                            case 4:
                              return moment(d).format('MMM');
                            case 5:
                              return moment(d).format('MMM');
                          }
                        }}
                      />
                      <YAxis
                        tickFormat={(tick) => {
                          let y = Math.round(tick) === tick ? tick : '';
                          if (String(y).length > 3) {
                            return format('.2s')(y);
                          } else {
                            return y;
                          }
                        }}
                      />
                      <DiscreteColorLegend
                        height={(15 * height) / 100}
                        width={width}
                        orientation='horizontal'
                        items={legends}
                        style={{ textAlign: 'center', overflow: 'hidden' }}
                      />
                      <ChartLabel
                        text={
                          router.pathname.includes('allincome') ||
                          router.pathname.includes('gemiincome') ||
                          router.pathname.includes('allexpenses')
                            ? 'Ποσό (€)'
                            : ''
                        }
                        includeMargin={false}
                        xPercent={0}
                        yPercent={0.04}
                        style={{
                          transform: 'rotate(0)',
                          textAnchor: 'end',
                        }}
                      />
                    </XYPlot>
                  </>
                )}
              </AutoSizer>
            </div>
            {/* </Fade> */}
          </div>
        </>
      ) : (
        <PlotLoader />
      )}
    </>
  );
};

export default LinePlot;
