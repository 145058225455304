import DateFnsUtils from "@date-io/date-fns";
import { createMuiTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import "date-fns";
import elLocale from "date-fns/locale/el";
import React, { Fragment } from "react";

const pickerTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#212121",
    },
  },
});

const DateYearPicker = (props) => {
  const {
    handleStartDateChange,
    handleEndDateChange,
    selectedStartDate,
    selectedEndDate,
    handleErrorOnStartDate,
    handleErrorOnEndDate,
    disabledDates,
    requiredDatesFlag,
    errorOnStartDate,
    errorOnEndDate,
  } = props;

  return (
    <Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={elLocale}>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ marginRight: 10, flexWrap: "nowrap" }}
        >
          <ThemeProvider theme={pickerTheme}>
            <KeyboardDatePicker
              views={["year"]}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                height: 80,
                marginLeft: 0,
                marginBottom: 0,
                marginTop: 0,
                width: 120,
              }}
              disabled={disabledDates}
              disableToolbar
              variant="inline"
              margin="normal"
              id="date-picker-dialog"
              label="Από"
              format="yyyy"
              value={selectedStartDate}
              onChange={(date) => handleStartDateChange(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              invalidDateMessage="Μη έγκυρη ημερομηνία"
              onError={(error) => handleErrorOnStartDate(error)}
              error={requiredDatesFlag || errorOnStartDate}
              maxDateMessage="Μη έγκυρη ημερομηνία"
            />
          </ThemeProvider>
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ marginLeft: 10, flexWrap: "nowrap" }}
        >
          <ThemeProvider theme={pickerTheme}>
            <KeyboardDatePicker
              views={["year"]}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                height: 80,
                marginLeft: 0,
                marginBottom: 0,
                marginTop: 0,
                width: 120,
              }}
              disabled={disabledDates}
              disableToolbar
              variant="inline"
              format="yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Έως"
              value={selectedEndDate}
              minDate={selectedStartDate}
              minDateMessage="Επιλέξτε μεταγενέστερη ημερομηνία "
              onChange={(date) => handleEndDateChange(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              invalidDateMessage="Μη έγκυρη ημερομηνία"
              error={requiredDatesFlag || errorOnEndDate}
              onError={(error) => handleErrorOnEndDate(error)}
            />
          </ThemeProvider>
        </Grid>
      </MuiPickersUtilsProvider>
    </Fragment>
  );
};

export default DateYearPicker;
