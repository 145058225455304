import { Fab, Grid, Switch, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import "moment/locale/el";
import { useContext, useEffect, useState } from "react";
import "react-vis/dist/style.css";
import { CompareHandling } from "../../src/context/CompareContext";
import { DataHandling } from "../../src/context/DataContext";
import { DateIndexHandling } from "../../src/context/DateIndexContext";
import { DateYearsHandling } from "../../src/context/DateYearsContext";
import { FreeRangeCompareHandling } from "../../src/context/FreeRangeCompareContext";
import { TypeHandling } from "../../src/context/TypeContext";
import dataTest from "../../src/dataTest/dataTest.json";
import ChangesBarChart from "./ChangesBarChart";
import LinePlot from "./LinePlot";
import {useRouter} from "next/router";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  gridContainer3: {
    margin: theme.spacing(1),
    width: "100%",
  },
  buttonGroup: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.secondary.main,
  },
  selectedDateRange: {
    color: theme.palette.secondary.main,
    background: theme.palette.primary.main,
    "&.Mui-selected": {
      color: theme.palette.primary.main,
      background: theme.palette.secondary.main,
    },
  },
  plot: {
    height: 350,
    width: "100%",
  },
  dateDisplayButton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 0,
    cursor: "auto",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  fabButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    boxShadow: "none",
  },
  centerDate: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textTransform: "none",
  },
}));

const GroupPlots = () => {
  const classes = useStyles();
  const router = useRouter();
  const [noData, setNoData] = useState(false);
  const { dateYearsSelected } = useContext(DateYearsHandling);
  const { currentDateIndex, setCurrentDateIndex } = useContext(
    DateIndexHandling
  );
  const { compare } = useContext(CompareHandling);
  const { data } = useContext(DataHandling);
  const { regDelCompareType } = useContext(TypeHandling);
  const { freeRangeCompare } = useContext(FreeRangeCompareHandling);

  const dateRangeOptions = dataTest.dateRangeOptions;
  const [checked, setChecked] = useState(false);

  const handleDateRange = (event, newDateRange) => {
    if (newDateRange !== null) {
      setCurrentDateIndex(newDateRange);
    }
  };

  const changePlot = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (data && data !== undefined) {
      let dataFound = true;
      for (const i in data) {
        if (data[i].length <= 0) {
          dataFound = false;
        }
      }
      if (dataFound) {
        setNoData(false);
      } else {
        setNoData(true);
      }
    }
  }, [data]);

  return (
    <Paper className={classes.paper}>
      {noData ? (
        <></>
      ) : (
        <ToggleButtonGroup
          id="date-range-button-group"
          value={currentDateIndex}
          exclusive
          onChange={handleDateRange}
          aria-label="date-button-group"
          className={classes.buttonGroup}
        >
            {!router.pathname.includes("allexpenses") ?
                dateYearsSelected === false ? (
                    <Tooltip
                        title="Δε μπορείτε να φιλτράρετε με αυτό το διάστημα"
                        placement="top-start"
                        arrow
                    >
              <span>
                <ToggleButton
                    disabled
                    className={classes.selectedDateRange}
                    value={0}
                    aria-label="day"
                >
                  {dateRangeOptions[0].shortcut}
                </ToggleButton>
              </span>
                    </Tooltip>
                ) : (
                    <ToggleButton
                        className={classes.selectedDateRange}
                        value={0}
                        aria-label="day"
                    >
                        {dateRangeOptions[0].shortcut}
                    </ToggleButton>
                )
                : ''}
            {!router.pathname.includes("allexpenses") ?
                dateYearsSelected === false ? (
                    <Tooltip
                        title="Δε μπορείτε να φιλτράρετε με αυτό το διάστημα"
                        placement="top-start"
                        arrow
                    >
              <span>
                <ToggleButton
                    disabled
                    className={classes.selectedDateRange}
                    value={1}
                    aria-label="week"
                >
                  {dateRangeOptions[1].shortcut}
                </ToggleButton>
              </span>
                    </Tooltip>
                ) : (
                    <ToggleButton
                        className={classes.selectedDateRange}
                        value={1}
                        aria-label="week"
                    >
                        {dateRangeOptions[1].shortcut}
                    </ToggleButton>
                )
                : ''}
          <ToggleButton
            className={classes.selectedDateRange}
            value={2}
            aria-label="month"
          >
            {dateRangeOptions[2].shortcut}
          </ToggleButton>
          <ToggleButton
            className={classes.selectedDateRange}
            value={3}
            aria-label="3 months"
          >
            {dateRangeOptions[3].shortcut}
          </ToggleButton>
          {/* <ToggleButton className={classes.selectedDateRange} value={3} aria-label="6 months">
                            {dateRangeOptions[4].shortcut}
                        </ToggleButton>
                        <ToggleButton className={classes.selectedDateRange} value={3} aria-label="year" >
                            {dateRangeOptions[5].shortcut}
                        </ToggleButton> */}
        </ToggleButtonGroup>
      )}
      {(compare || regDelCompareType) && !freeRangeCompare ? (
        <Grid style={{ width: "100%" }}>
          <Grid item style={{ float: "right" }}>
            <FormControlLabel
              control={
                <Switch
                  inputProps={{ "aria-label": "primary checkbox" }}
                  checked={checked}
                  onClick={changePlot}
                />
              }
              label="Δείτε εδώ τη μεταβολή"
              labelPlacement="start"
            />
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Grid style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <Grid item className={classes.gridContainer3}>
          {checked && (compare || regDelCompareType) && !freeRangeCompare ? (
            <ChangesBarChart />
          ) : (
            <LinePlot />
          )}
        </Grid>
        {/* <Grid item
                    className={classes.gridContainer3}
                >
                    <BarChart  />
                </Grid> */}
      </Grid>

      {noData ? (
        <></>
      ) : (
        <>
          <div style={{ display: "inline-flex", alignItems: "center" }}>
              {currentDateIndex === 0 || (currentDateIndex === 2 && router.pathname.includes("allexpenses"))? (
              <></>
            ) : (
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                colSpan={6}
              >
                {dateRangeOptions[currentDateIndex - 1].name}
              </Typography>
            )}
            <Typography
              variant="button"
              display="block"
              gutterBottom
              colSpan={6}
              className={classes.centerDate}
            >
              {dateRangeOptions[currentDateIndex].nameUpperCase}
            </Typography>
            {currentDateIndex === 3 ? (
              <></>
            ) : (
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                colSpan={6}
              >
                {dateRangeOptions[currentDateIndex + 1].name}
              </Typography>
            )}
          </div>
          <div style={{ display: "inline-flex", alignItems: "center" }}>
            {currentDateIndex === 0 || (currentDateIndex === 2 && router.pathname.includes("allexpenses"))? (
              <Fab
                disabled
                aria-label="back-button"
                type="submit"
                size="small"
                className={classes.fabButton}
                style={{ verticalAlign: "center" }}
                onClick={() => {
                  setCurrentDateIndex(currentDateIndex - 1);
                }}
              >
                <ArrowBackIcon fontSize="small" color="disabled" />
              </Fab>
            ) : currentDateIndex === 2 && dateYearsSelected === false ? (
              <Tooltip
                title="Δε μπορείτε να φιλτράρετε με αυτό το διάστημα."
                placement="top-start"
                arrow
              >
                <span>
                  <Fab
                    disabled
                    aria-label="back-button"
                    type="submit"
                    size="small"
                    className={classes.fabButton}
                    style={{ verticalAlign: "center" }}
                    onClick={() => {
                      setCurrentDateIndex(currentDateIndex - 1);
                    }}
                  >
                    <ArrowBackIcon fontSize="small" color="disabled" />
                  </Fab>
                </span>
              </Tooltip>
            ) : (
              <Fab
                aria-label="back-button"
                type="submit"
                size="small"
                className={classes.fabButton}
                style={{ verticalAlign: "center" }}
                onClick={() => {
                  setCurrentDateIndex(currentDateIndex - 1);
                }}
              >
                <ArrowBackIcon fontSize="small" color="secondary" />
              </Fab>
            )}
            <Button
              id="filter-button"
              type="submit"
              variant="outlined"
              className={classes.dateDisplayButton}
            >
              {dateRangeOptions[currentDateIndex].nameUpperCase}
            </Button>
            {currentDateIndex === 3 ? (
              <Fab
                disabled
                aria-label="forward-button"
                type="submit"
                size="small"
                className={classes.fabButton}
                style={{ verticalAlign: "center" }}
                onClick={() => {
                  setCurrentDateIndex(currentDateIndex + 1);
                }}
              >
                <ArrowForwardIcon fontSize="small" color="disabled" />
              </Fab>
            ) : (
              <Fab
                aria-label="forward-button"
                type="submit"
                size="small"
                className={classes.fabButton}
                style={{ verticalAlign: "center" }}
                onClick={() => {
                  setCurrentDateIndex(currentDateIndex + 1);
                }}
              >
                <ArrowForwardIcon fontSize="small" color="secondary" />
              </Fab>
            )}
          </div>
        </>
      )}
    </Paper>
  );
};

export default GroupPlots;
